import React from "react";
import Page from "../../components/layout/page";
import { Col, Container, Image, Row, Title } from "../../components/ui";
import { useRouter } from "next/router";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import Head from "next/head";
import { AppBanner } from "../../components/AppBanner";
import Link from "next/link";

function AppPage() {
    // Translation
    const router = useRouter();
    React.useEffect(() => {
        ReactGA_UA.pageview(router.asPath);
        ReactGA_4.send({ hitType: "pageview", page: router.asPath });
    });
    return (
        <Page>
            <Head>
                <title>Spara appið</title>
            </Head>

            <div className="container">
                <div className="col-sm-9 col-md-9 mx-auto">
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: 40,
                            fontWeight: "bold",
                        }}
                    >
                        Sæktu Spara frítt í símann þinn
                    </p>
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: 20,
                        }}
                        className="mb-5"
                    >
                        Smelltu á þá verslun sem þú vilt fara í.
                    </p>
                    <Container>
                        <Row>
                            <Col md={4} className="mx-auto">
                                <Link
                                    href="https://apps.apple.com/is/app/1819-torgið/id1559929453"
                                    prefetch={false}
                                    target={"_blank"}
                                    aria-label="App Store"
                                >
                                    <Image alt="App Store" src="/apple_app_store.png" />
                                </Link>
                            </Col>
                            <Col md={4} className="mx-auto">
                                <Link
                                    href="https://play.google.com/store/apps/details?id=com.torgid"
                                    prefetch={false}
                                    target={"_blank"}
                                    aria-label="Google Play"
                                >
                                    <Image alt="Google Play" src="/google_play.png" />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Page>
    );
}

export default AppPage;
